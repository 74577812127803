import React from 'react'
import Cbutton from '../components/type/button'
import "../styles/home.css";
import { Link } from "react-router-dom";

export const Home = ({ text, img }) => {

  return (
    <div className="home">
      <div className="calculate" style={{ "background": `url(${img[0].url})`, "backgroundSize": "cover" }}>
        <h3>{text[0]}</h3>
        <h4>{text[1]}</h4>
        <div className="row">
          <Link to='/calculator' style={{"color": "white"}}>
            <div className="inputbox d-flex">
              <input type="text" placeholder={text[2]} />
              <div className="circle">
                <i className="fa-solid fa-arrow-right fa-2xl" id='icontostyle'></i>
              </div>
            </div></Link>
        </div>

        <div className="row">
          <a href="tel:3226481069"><button><i className="fa-solid fa-phone"></i> {text[3]}</button></a>
        </div>
      </div>
      <div className="whyus">
        <h2>{text[4]}</h2>

        <div className="row">
          <div className="col-lg-4">
            <h5>{text[5]}</h5>
            <p>{text[6]}</p>
          </div>
          <div className="col-lg-4">
            <h5>{text[7]}</h5>
            <p>{text[8]}</p>
          </div>
          <div className="col-lg-4">
            <h5>{text[9]}</h5>
            <p>{text[10]}</p>
          </div>
        </div>
      </div>

      <div className="pages">
        <h4>{text[11]}</h4>
        <h3>{text[12]}</h3>
        <div className="pagelist">
          <div className="row">
            <div className="col-lg-6 d-flex">
              <Link to="/calculator"><h3>{text[13]}</h3>
              <div className="pageicon">
                <i className="fa-solid fa-arrow-right fa-2xl"></i>
              </div>
              </Link>
            </div>
            <div className="col-lg-6 d-flex">
              <Link to="/chargers"><h3>{text[14]}</h3>
              <div className="pageicon">
                <i className="fa-solid fa-arrow-right fa-2xl"></i>
              </div>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 d-flex">
              <Link to="/battery"><h3>{text[15]}</h3>
              <div className="pageicon">
                <i className="fa-solid fa-arrow-right fa-2xl"></i>
              </div>
              </Link>
            </div>
            <div className="col-lg-6 d-flex">
              <a href="https://cey-tech.be/"><h3>{text[16]}</h3>
              <div className="pageicon">
                <i className="fa-solid fa-arrow-right fa-2xl"></i>
              </div>
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 d-flex">
              <Link to="/gallery"><h3>{text[17]}</h3>
              <div className="pageicon">
                <i className="fa-solid fa-arrow-right fa-2xl"></i>
              </div>
              </Link>
            </div>
            <div className="col-lg-6 d-flex">
              <Link to="/contact"><h3>{text[18]}</h3>
              <div className="pageicon">
                <i className="fa-solid fa-arrow-right fa-2xl"></i>
              </div>
              </Link>
            </div>
          </div>
        </div>
      </div>


      <div className="contactus">
        <h3>{text[19]}</h3>
        <h2>{text[20]}</h2>
        <Link to="/calculator"><Cbutton title={text[21]} /></Link>
      </div>
    </div>
  )
}

export default Home;
