import React from 'react'
import "../styles/footer.css"
import { Link } from "react-router-dom"

export const Footer = ({ img,text }) => {
  return (
    <footer className="text-center text-white text-lg-start bg-dark ">
      <section
        className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom"
      >
        <div className="me-5 d-none d-lg-block">
          <span>{text[0]}</span>
        </div>

        <div className='sociallinks'>
          <a href={text[1]} className="me-4 text-reset">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href={text[2]} className="me-4 text-reset">
            <i className="fab fa-instagram"></i>
          </a>
        </div>
      </section>

      <section className="">
        <div className="container text-center text-md-start mt-5">
          <div className="row mt-3">
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                {text[4]}
              </h6>
              <p>
              {text[5]}
                
              </p>
              <a target="_blank" href="https://www.cey-tech.be" rel="noopener noreferrer">
                <h6>{text[6]}</h6>
                <img src={img[22].url} alt={img[22].alt} style={{ "width": "100%" }} />
              </a>
            </div>

            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
              {text[7]}
              </h6>
              <p>
                <Link to="/calculator">{text[8]}</Link>
              </p>
              <p>
                <Link to="/battery">{text[9]}</Link>
              </p>
              <p>
                <Link to="/chargers">{text[10]}</Link>
              </p>
            </div>

            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                {text[11]}
              </h6>
              <p>
                <a href="https://cey-tech.be/" target="_blank">{text[12]}</a>
              </p>
              <p>
                <Link to="/contact">{text[13]}</Link>
              </p>
              <p>
                <Link to="/gallery"> {text[14]}</Link>
              </p>
            </div>

            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                {text[15]}
              </h6>
              <p><i className="fas fa-home me-3"></i> CEYTECH Group BV
                imperiastraat 8
                1930 Zaventem
                Belgium</p>
              <p>
                <i className="fas fa-envelope me-3"></i>
                <a href="mailto:info@cey-tech.be">info@cey-tech.be</a>
              </p>
              <a href="tel:3226481069"><p><i className="fas fa-phone me-3"></i> + 32 2 648 10 69</p></a>
              <a href="https://wa.me/32489921363"><p><i className="fa-brands fa-whatsapp"></i> + 32 489 92 13 63 (Whatsapp)</p></a>
            </div>
          </div>
        </div>
      </section>

      <div className="text-center p-4 cprtext">
        © 2022 Copyright:
        <a className="text-reset fw-bold" href="https://ceysolar.be/"> ceysolar.be</a>
      </div>
    </footer>
  )
}

export default Footer;