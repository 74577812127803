import React, { useState, useEffect } from 'react'
import Sbg from '../components/type/startbg'
import "../styles/gallery.css";

export const Gallery = ({ text, img }) => {
    const [gallery, setGallery] = useState();
    const [status, setStatus] = useState();
    useEffect(() => {
        setStatus('Loading');
        fetch('https://api.cey-solar.com/api/gallery')
            .then(response => response.json())
            .then(setGallery)
            .then(() => {setStatus('Success')})
            .then(console.log(gallery))
            .catch(() => setStatus('Error'));
    }, []);
    return (

        <div className='gallery'>
            {status === 'Loading' && <div>Loading...</div>}
            {status === 'Error' && <div>There was an error</div>}
            {status === 'Success' &&
                <>
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 introcol">
                            <div className="div">
                                <h2>{text[0]}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="gallerysliders">
                        {
                            gallery.map((el, i) => {
                                return (
                                    <div className="row">
                                        <div className="col-lg-10 offset-lg-1">
                                            <h3>{gallery[i].type}</h3>
                                            <div className="row galcards">
                                                {
                                                    gallery[i].items.map((el, idx) => {
                                                        return (
                                                            <div className="col-lg-4 ">
                                                                <div className="gallerycard">
                                                                    <div className="gallerytitle">
                                                                        <h4>{gallery[i].items[idx].place}</h4>
                                                                    </div>
                                                                    <div className="galleryinfo">
                                                                        <h4>{gallery[i].items[idx].kwpower+text[3]}</h4>
                                                                        <h4>{gallery[i].items[idx].oilsaved+text[4]}</h4>
                                                                        <h4>{gallery[i].items[idx].savedperyear+text[5]}</h4>
                                                                        <h4>{gallery[i].items[idx].panel+text[6]}</h4>
                                                                    </div>
                                                                    <div className="galleryimage">
                                                                        <img src={gallery[i].items[idx].img} alt="house" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <Sbg type={2} color="black" bg="linear-gradient(164deg, rgba(58,180,135,1) 0%, rgba(55,132,234,1) 18%, rgba(104,151,199,1) 39%, rgba(130,178,199,1) 60%, rgba(252,176,69,1) 78%, rgba(252,176,69,1) 96%)" heading={text[7]} desc="" detail={text[8]} />
                </>}
        </div>
    )
}

export default Gallery;