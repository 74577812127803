import React from 'react'
import Sbg from '../components/type/startbg';
import Cbutton from '../components/type/button';
import "../styles/battery.css";
import Accordion from '../components/type/faq';
import { Link } from 'react-router-dom';

export const Battery = ({ text,img }) => {

    var faq = [[text[33], text[34]], [text[35], text[36]], [text[37], text[38]], [text[39], text[40]], [text[41], text[42]], [text[43], text[44]]]
    return (
        <div id='battery' className='battery'>
            <Sbg type={1} heading={text[0]} desc={text[1]} detail={text[2]} color="black" bg={img[5].url} />
            <div className='bsections'>
                <div className='bsection'>
                    <h4>{text[3]}</h4>
                    <div className="row">
                        <div className="col-lg-4">
                            <h5>{text[4]}</h5>
                            <p>{text[5]}</p>
                        </div>
                        <div className="col-lg-4">
                            <h5>{text[6]}</h5>
                            <p>{text[7]}</p>
                        </div>
                        <div className="col-lg-4">
                            <h5>{text[8]}</h5>
                            <p>{text[9]}</p>
                        </div>
                    </div>
                </div>
                <div className="bsection compare">
                    <div className="textsection">
                        <h4>{text[10]}</h4>
                        <h3>{text[11]}</h3>
                    </div>
                    <div className="row hybrid">
                        <div className="col-lg-5">
                            <i className="fa-solid fa-bolt"></i>
                            <h4>{text[12]}</h4>
                            <p>{text[13]}</p>
                        </div>
                        <div className="col-lg-5">
                            <i className="fa-solid fa-hand-holding-dollar"></i>
                            <h4>{text[14]}</h4>
                            <p>{text[15]}</p>

                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-auto align-self-center">
                            <Link to="/calculator"><Cbutton title={text[16]} /></Link>
                        </div>

                    </div>
                </div>

            </div>
            <Sbg type={1} color="white" bg={img[6].url} heading={text[17]} desc={text[18]} detail={text[19]} />
            <div className="bsectiontwo">
                <div className="bsection">
                    <h4>{text[20]}</h4>
                    <h3>{text[21]}</h3>
                    <p>{text[22]}</p>
                    <div className="row percents">
                        <div className="col-lg-3">
                            <div className="percent no-round animate">
                                <svg>
                                    <circle cx="70" cy="70" r="70"></circle>
                                    <circle cx="70" cy="70" r="70"></circle>
                                </svg>
                                <div className="number">
                                    <p>30 <span>%</span> </p>
                                </div>
                                <h2 className='text'>{text[23]}</h2>

                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="percent no-round animate">
                                <svg>
                                    <circle cx="70" cy="70" r="70"></circle>
                                    <circle cx="70" cy="70" r="70"></circle>
                                </svg>
                                <div className="number">
                                    <p>55 <span>%</span> </p>
                                </div>
                                <h2 className='text'>{text[24]}</h2>

                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="percent no-round animate">
                                <svg>
                                    <circle cx="70" cy="70" r="70"></circle>
                                    <circle cx="70" cy="70" r="70"></circle>
                                </svg>
                                <div className="number">
                                    <p>70 <span>%</span> </p>
                                </div>
                                <h2 className='text'>{text[25]}</h2>

                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="percent no-round animate">
                                <svg>
                                    <circle cx="70" cy="70" r="70"></circle>
                                    <circle cx="70" cy="70" r="70"></circle>
                                </svg>
                                <div className="number">
                                    <p>75 <span>%</span> </p>
                                </div>
                                <h2 className='text'>{text[26]}</h2>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Sbg type={2} color="black" bg={`url(${img[7].url})`} heading={text[27]} desc={text[28]} detail={text[29]} />
            <div className="faq">
                <div className="row">
                    <div className="col-lg-4">
                        <h4>{text[30]}</h4>
                        <h2>{text[31]}</h2>
                    </div>
                    <div className="col-lg-8">
                        <div className="quests">
                            {faq.map((element, index) => {
                                return (
                                    <Accordion q={element[0]} a={element[1]}/>
                                )
                            })

                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Battery;