import './App.css';
import { useEffect, useState } from 'react';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import About from "./pages/about";
import Home from "./pages/home";
import Header from './components/header';
import Footer from './components/footer';
import Contact from './pages/contact';
import Battery from './pages/battery';
import Chargers from './pages/chargers';
import Gallery from './pages/gallery';
import Solar from './pages/solar';
import Panel from './pages/panel';
import Test from './components/type/test'

function App() {
  const [status, setStatus] = useState();
  const [locale, setLocale] = useState();
  const [lang, setLang] = useState("fr");
  const [img, setimg] = useState();
  useEffect(() => {
    fetch('https://api.cey-solar.com/api/locale')
      .then(response => response.json())
      .then(setLocale)
      .then(() => fetch('https://api.cey-solar.com/api/img')
        .then(response => response.json())
        .then(setimg)
        .then(() => setStatus('Success'))
        .catch(() => setStatus('Error')))
      .catch(() => setStatus('Error'))
  }, [])
  return (
    <div className="App">
      {status === 'Loading' && <div>Loading...</div>}
      {status === 'Error' && <div>There was an error</div>}
      {status === 'Success' &&
        <>
          <BrowserRouter>
            <Header img={img} text={locale.header[lang]} setlang={setLang} clang={lang} />
            <div className='container-lg'>
              <Routes>
                <Route path="/" element={<Home text={locale.home[lang]} img={img} />} />
                <Route path="/index.html" element={<Home text={locale.home[lang]} img={img} />} />
                <Route path="about" element={<About text={locale.about[lang]} img={img} />} />
                <Route path="contact" element={<Contact text={locale.contact[lang]} img={img} />} />
                <Route path="battery" element={<Battery text={locale.battery[lang]} img={img} />} />
                <Route path="chargers" element={<Chargers text={locale.charger[lang]} img={img} />} />
                <Route path="gallery" element={<Gallery text={locale.gallery[lang]} img={img} />} />
                <Route path="calculator" element={<Solar text={locale.calculator[lang]} img={img} />} />
                <Route path="panel" element={<Panel img={img} text={locale} />} />
                <Route path="test" element={<Test />}
                />

              </Routes>
            </div>
            <Footer img={img} text={locale.footer[lang]}/>
          </BrowserRouter>
        </>
      }
    </div>
  );
}

export default App;
