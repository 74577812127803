import React from 'react'
import Cbutton from './button';
import { Link } from 'react-router-dom';
export const Sbg = ({ heading, desc, detail, bg, color, type, event }) => {
  return (
    <div>
      {type === 1? <div className='sbg sbgtype1' style={{ background: `url(${bg})`, backgroundSize: "cover", color: color }}>
        <h3>{heading}</h3>
        <h1>{desc}</h1>
        <h4>{detail}</h4>
      </div>
      
    : 
    <div className='sbg sbgtype2' style={{ background: bg, backgroundSize: "cover", color: color }}>
        <h4>{heading}</h4>
        <h2>{desc}</h2>
        <Link to="/calculator"><Cbutton title={detail} event={event} /></Link>
      </div>
    }
    </div>
  )
}

export default Sbg;