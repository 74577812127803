import React from 'react'
import Cbutton from '../components/type/button'
import Accordion from '../components/type/faq'
import Sbg from '../components/type/startbg'
import "../styles/chargers.css";
import { Link } from 'react-router-dom';

export const Chargers = ({ text,img }) => {
 
    var faq = [[text[39], text[40]], [text[41], text[42]], [text[43], text[44]], [text[45], text[46]], [text[47], text[48]], [text[49], text[50]]]
    return (
        <div id='chargers' className='chargers'>
            <Sbg type={1} heading={text[0]} desc={text[1]} detail={text[2]} color="black" bg={img[8].url} />
            <div className='bsections'>
                <div className='bsection'>
                    <h4>{text[3]}</h4>
                    <div className="row">
                        <div className="col-lg-4">
                            <h5>{text[4]}</h5>
                            <p>{text[5]}</p>
                        </div>
                        <div className="col-lg-4">
                            <h5>{text[6]}</h5>
                            <p>{text[7]}</p>
                        </div>
                        <div className="col-lg-4">
                            <h5>{text[8]}</h5>
                            <p>{text[9]}</p>
                        </div>
                    </div>
                </div>
                <div className="bsection introcharger">
                    <div className="textsection">
                        <h4>{text[10]}</h4>
                        <h3>{text[11]}</h3>
                    </div>
                    <div className="row ">
                        <div className="col-lg-6 infocharger">
                            <div className="row">
                                <div className="col-lg-6">
                                    <h4>{text[12]}</h4>
                                    <h6>{text[13]}</h6>
                                </div>
                                <div className="col-lg-6">

                                    <h4>{text[14]}</h4>
                                    <h6>{text[15]}</h6>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">

                                    <h4>{text[16]}</h4>
                                    <h6>{text[17]}</h6>
                                </div>
                                <div className="col-lg-6">

                                    <h4>{text[18]}</h4>
                                    <h6>{text[19]}</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img src={img[9].url} alt={img[9].alt}/>
                        </div>
                    </div>
                </div>

            </div>
            <div className="row justify-content-center">
                <div className="col-lg-auto align-self-center">
                    <Link to="/calculator"><Cbutton title={text[20]} /></Link>
                </div>
            </div>
            <div className="row chargerphotos">
                <div className="col-lg-6">
                    <img src={img[10].url} alt={img[10].alt} />
                </div>
                <div className="col-lg-6">
                    <img src={img[11].url} alt={img[11].alt} />
                </div>
            </div>
            <div className="bsectiontwo">
                <div className="bsection howtoorder">
                    <h4>{text[21]}</h4>
                    <h3>{text[22]}</h3>
                    <img src={img[12].url} alt={img[12].alt} />
                    <div className="row">
                        <div className="col-lg-3">
                            <h4>{text[23]}</h4>
                            <h5>{text[24]}</h5>
                        </div>
                        <div className="col-lg-3">
                            <h4>{text[25]}</h4>
                            <h5>{text[26]}</h5>
                        </div>
                        <div className="col-lg-3">
                            <h4>{text[27]}</h4>
                            <h5>{text[28]}</h5>
                        </div>
                        <div className="col-lg-3">
                            <h4>{text[29]}</h4>
                            <h5>{text[30]}</h5>
                        </div>
                    </div>
                </div>
                <div className="bsection loadbalance">
                    <div className="row">
                        <div className="col-lg-6">
                            <h4>{text[31]}</h4>
                            <h3>{text[32]}</h3>
                            <p>{text[33]}</p>
                        </div>
                        <div className="col-lg-6">
                            <img src={img[13].url} alt={img[13].alt} />
                        </div>
                    </div>
                </div>
            </div>
            
            <Sbg type={2} color="black" bg="linear-gradient(164deg, rgba(58,180,135,1) 0%, rgba(55,132,234,1) 18%, rgba(104,151,199,1) 39%, rgba(130,178,199,1) 60%, rgba(252,176,69,1) 78%, rgba(252,176,69,1) 96%)" heading={text[34]} desc={text[35]} detail={text[36]} />
            <div className="faq">
                <div className="row">
                    <div className="col-lg-4">
                        <h4>{text[37]}</h4>
                        <h2>{text[38]}</h2>
                    </div>
                    <div className="col-lg-8">
                        <div className="quests">
                            {faq.map((element, index) => {
                                return (
                                    <Accordion q={element[0]} a={element[1]} />
                                )
                            })

                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Chargers;