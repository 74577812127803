

import React, { useState, useEffect } from 'react'
import {Link} from 'react-router-dom'

import "../styles/solar.css"
import Map from "../components/type/map"
import SearchLocationInput from '../components/type/autocomplete'

export const Solar = ({ text, img }) => {
    const [page, setPage] = useState(0)
    const [rooftype, setRooftype] = useState(0)
    const [paneltype, setPaneltype] = useState(0)
    const [battery, setBattery] = useState(false)
    const [charger, setCharger] = useState(false)
    const [status, setStatus] = useState();
    const [years, setyears] = useState(false);
    const [op, setop] = useState();
    const [data, setData] = useState({
        address: "",
        roofspace: "",
        panelCount: "",
        slope: "",
        old: false,
        panel: "",
        additions: [],
        cost: 0,
        savings: 0

    })
    const [form, setform] = useState({});
    useEffect(() => {
        setStatus('Loading');
        fetch('https://api.cey-solar.com/api/panel')
            .then(response => response.json())
            .then(setop)
            .then(() => setStatus('Success'))
            .catch(() => setStatus('Error'));
    }, [])
    useEffect(() => {
        if (status === 'Success') {

            function adddition() {
                var extra = 0
                if (charger) {
                    extra = extra + Number(op.chargerprice)
                }
                if (battery) {
                    extra = extra + Number(op.batteryprice)
                }
                return extra
            }
            if (paneltype === "0") {
                setData({
                    ...data,
                    cost: Math.ceil(((data.roofspace / Number(op.panelsize)) * Number(op.ecoprice)) + adddition()),
                    savings: Math.ceil(((data.roofspace / Number(op.panelsize)) * Number(op.ecowatt)) / Number(op.yearlyprim))
                });
            } else {
                setData({
                    ...data,
                    cost: Math.ceil(((data.roofspace / Number(op.panelsize)) * Number(op.premiumprice)) + adddition()),
                    savings: Math.ceil(((data.roofspace / Number(op.panelsize)) * Number(op.premiumwatt)) / Number(op.yearlyprim))
                });
            }
        }
    }, [status, charger, op, battery, paneltype]);
    function handleChange(evt) {
        const value = evt.target.value;
        setData({
            ...data,
            [evt.target.name]: value
        });

    }
    function handleForm(evt) {
        const value = evt.target.value;
        setform({
            ...form,
            [evt.target.name]: value
        });
        console.log(form)
    }

    function handlecheckbox() {
        setyears(!years)
        console.log(years)
    }
    function changePage(p) {
        setPage(p)
    }
    function increasePage() {
        setPage(page + 1)
    }
    function decreasePage() {
        setPage(page - 1)
    }
    function radioChange(event) {
        setPaneltype(event.target.value)
    }

    function radioChange2(event) {
        setRooftype(event.target.value)
    }
    function addCh(e, s) {
        if (s) {
            setData({
                ...data,
                address: e.target.value
            })
        } else {
            setData({
                ...data,
                address: e
            })
            console.log(data)
            increasePage()
        }
    }
    function order() {
        var payload = {
            name: form.name,
            sname: form.sname,
            email: form.mail,
            phone: form.phone,
            address: form.address,
            code: form.code,
            city: form.city,
            zip: form.zip,
            data: data,
            battery: battery,
            charger: charger,
            rooftype: rooftype,
            paneltype: paneltype,
            year: years,
            date: new Date()

        }
        fetch("https://api.cey-solar.com/api/customer", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        }).then(res => {
            if (res.status === 200) {
                document.getElementsByClassName('quoteform')[0].innerHTML = "Form has been successfully submitted";
            }
        });
    }
    return (

        <div className="calculator">
            {status === 'Loading' && <div>Loading...</div>}
            {status === 'Error' && <div>There was an error</div>}
            {status === 'Success' && <div className="calculator-box">
                <div className="boxnav">
                    <div className="row">
                        <div className="col-lg-7 col d-flex" >
                            <div className="div" onClick={() => { changePage(0) }}>
                                {page === 0 ? <h4 className='underline'>{text[0]}</h4> : <h4>{text[0]}</h4>}
                            </div>
                            <div className="div" onClick={() => { if (page > 1) { changePage(1) } }}>
                                {page === 1 ? <h4 className='underline'>{text[1]} <span>{Math.floor(data.roofspace)}</span></h4> : <h4>{text[1]} <span>{Math.floor(data.roofspace)}</span></h4>}

                            </div>
                            <div className="div" onClick={() => { if (page > 2) { changePage(2) } }}>
                                {page === 2 ? <h4 className='underline'>{text[2]} <span>{
                                    {
                                        0: "Flat",

                                        1: "Low Slope",

                                        2: "Conventional Slope"
                                    }[rooftype]
                                }</span> </h4> : <h4>{text[2]} <span>{
                                    {
                                        0: "Flat",

                                        1: "Low Slope",

                                        2: "Conventional Slope"
                                    }[rooftype]
                                }</span></h4>}

                            </div>
                            <div className="div" onClick={() => { if (page > 3) { changePage(3) } }}>
                                {page === 3 ? <h4 className='underline'>{text[3]} <span>{
                                    {
                                        0: "Eco",
                                        1: "Premium"
                                    }[paneltype]
                                }
                                </span></h4> : <h4>{text[3]} <span>{
                                    {
                                        0: "Eco",
                                        1: "Premium"
                                    }[paneltype]
                                }
                                </span></h4>}

                            </div>
                            <div className="div" onClick={() => { if (page > 4) { changePage(4) } }}>
                                {page === 4 ? <h4 className='underline'>{text[4]}</h4> : <h4>{text[4]}</h4>}

                            </div>
                        </div>
                        <div className="col-lg-5 col d-flex">
                            <div className="div saving">
                                <h4>{data.savings} €</h4>
                                <h4>{text[6]}</h4>
                            </div>
                            <div className="div">
                                <h4>{data.cost} €</h4>
                                <h4>{text[8]}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="boxcontent" >
                    {
                        {
                            0: <div className='row page1' >
                                <div className="col-lg-6 mapimg" style={{ "background": `url(${img[15].url})` }}>
                                </div>
                                <div className="col-lg-6">
                                    <div className="content-in">
                                        <div className="center">
                                            <h4>{text[9]}</h4>
                                            <h5>{text[10]}</h5>
                                            <SearchLocationInput onCh={(e, s) => { addCh(e, s) }} />
                                            <br />
                                            <Link to="/contact">{text[62]}</Link>
                                        </div>
                                        <div className="next">
                                            <button onClick={() => { if (data.address && data.address !== "") { increasePage() } }} id='buttonnext'>{text[12]}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>,
                            1: <div className='row page2'>
                                <div className="col-lg-6 mappbox">
                                    <Map zoom={20} address={data.address} containerElement={<div style={{ height: `100%` }} />}
                                        mapElement={<div className='mapdiv' style={{ height: `100%` }} />} callback={(ef) => {
                                            setData({
                                                ...data,
                                                roofspace: ef
                                            })
                                        }} />
                                </div>
                                <div className="col-lg-6">
                                    <div className="content-in">
                                        <div className="center">
                                            <h4>{text[13]}</h4>
                                            <h5>{text[14]}</h5>
                                            <h5>{text[61]}</h5>
                                            <input readOnly name='roofspace' onChange={(e) => { handleChange(e) }} value={data.roofspace} type="number" />
                                        </div>
                                        <div className="next">
                                            <button onClick={() => { decreasePage() }} id='buttonback'>{text[20]}</button>
                                            <button onClick={() => { if (data.roofspace && data.roofspace !== "") { increasePage() } }} id='buttonnext'>{text[21]}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>,
                            2: <div className='row page3'>
                                <div className="col-lg-6">

                                    <img src={
                                        {
                                            0:
                                                img[16].url,
                                            1:
                                                img[17].url,
                                            2:
                                                img[18].url
                                        }[rooftype]
                                    } alt="house" />
                                </div>
                                <div className="col-lg-6">
                                    <div className="content-in">
                                        <div className="center">
                                            <h4>{text[15]}</h4>
                                            <h5>{text[16]}</h5>
                                            <div className="radio-section">
                                                <input checked={rooftype === "0"} type="radio" id="panel1" name="roof" value="0" onChange={(e) => { radioChange2(e) }} />
                                                <label htmlFor="panel1"><span>{text[17]}</span><span>0-15°</span></label>
                                                <input checked={rooftype === "1"} type="radio" id="panel2" name="roof" value="1" onChange={(e) => { radioChange2(e) }} />
                                                <label htmlFor="panel2"><span>{text[18]}</span><span>15-30°</span></label>
                                                <input checked={rooftype === "2"} type="radio" id="panel3" name="roof" value="2" onChange={(e) => { radioChange2(e) }} />
                                                <label htmlFor="panel3"><span>{text[19]}</span><span>30° &gt;</span></label>
                                            </div>
                                            <div className="checkbox-c">

                                                <input onChange={() => { handlecheckbox() }} checked={!years} type="checkbox" name="yearsyo" id="yearsyo" />
                                                <label htmlFor="yearsyo">{text[57]}</label>

                                            </div>
                                        </div>
                                        <div className="next">
                                            <button onClick={() => { decreasePage() }} id='buttonback'>{text[20]}</button>
                                            <button onClick={() => { if (rooftype && rooftype !== "") { increasePage() } }} id='buttonnext'>{text[21]}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>,
                            3: <div className='row page4'>
                                <div className="col-lg-6">

                                    <img src={
                                        {
                                            0:
                                                img[19].url,
                                            1:
                                                img[20].url
                                        }[paneltype]
                                    } alt="house" />
                                </div>
                                <div className="col-lg-6">
                                    <div className="content-in">
                                        <div className="center">
                                            <h4>{text[22]}</h4>
                                            <h5>{text[23]}</h5>
                                            <div className="paneltype radio-section d-flex">
                                                <input checked={paneltype === "0"} type="radio" id="ptype1" name="ptype" value="0" onChange={(e) => { radioChange(e) }} />
                                                <label htmlFor="ptype1"><span>{text[24]}</span></label>
                                                <input checked={paneltype === "1"} type="radio" id="ptype2" name="ptype" value="1" onChange={(e) => { radioChange(e) }} />
                                                <label htmlFor="ptype2"><span>{text[30]}</span></label>

                                            </div>
                                            {
                                                {
                                                    0:
                                                        <div className="paneldesc">
                                                            <div className="ptitle">
                                                                <h5><span>{text[24]}</span><span>{text[26]}</span></h5>
                                                            </div>
                                                            <div className="pdetail">
                                                                <h5><span>{text[27]}</span><span>{text[28]}</span></h5>
                                                            </div>
                                                            <div className="pdesc">
                                                                <p>{text[29]}</p>
                                                                <p>{text[25]}</p>
                                                            </div>
                                                        </div>,
                                                    1:
                                                        <div className="paneldesc">
                                                            <div className="ptitle">
                                                                <h5><span>{text[30]}</span><span>{text[34]}</span></h5>
                                                            </div>
                                                            <div className="pdetail">
                                                                <h5><span>{text[31]}</span><span>{text[32]}</span></h5>
                                                            </div>
                                                            <div className="pdesc">
                                                                <p>{text[35]}</p>
                                                                <p>{text[33]}</p>
                                                            </div>
                                                        </div>

                                                }[paneltype]
                                            }
                                            <div className="additions">
                                                <h4>Additions:</h4>
                                                <div className={battery ? "batteryad selectedaddition" : "batteryad"} onClick={() => { setBattery(!battery) }}>
                                                    <i className="fa-solid fa-battery-full"></i> Battery</div>
                                                <div className={charger ? "chargerad selectedaddition" : "chargerad"} onClick={() => { setCharger(!charger) }}>
                                                    <i className="fa-solid fa-bolt"></i> Car Charger</div>

                                            </div>
                                        </div>
                                        <div className="next">
                                            <button onClick={() => { decreasePage() }} id='buttonback'>{text[20]}</button>
                                            <button onClick={() => { increasePage() }} id='buttonnext'>{text[21]}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>,
                            4:
                                <div className='row page5'>
                                    <div className="col-lg-6">
                                        <div className="calculation-result">
                                            <h6>{text[48]}</h6>

                                            <div className="household">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <p><i className="fa-solid fa-layer-group"></i> {paneltype === 1 ? text[30] : text[24]}</p>
                                                        <p><i className="fa-solid fa-border-all"></i> {Math.floor(data.roofspace / 4)} {text[49]}</p>
                                                        <p><i className="fa-solid fa-house"></i> {
                                                            {
                                                                0:
                                                                    text[17]
                                                                ,
                                                                1:
                                                                    text[18]

                                                                ,
                                                                2:
                                                                    text[19]


                                                            }[rooftype]

                                                        }</p>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <p><i className="fa-solid fa-battery-full"></i> {text[58]} : {!battery ? text[50] : text[63]}</p>
                                                        <p><i className="fa-solid fa-bolt"></i> {text[59]} : {!charger ? text[50] : text[63]}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="resultinv">
                                                <h6>{text[60]}</h6>
                                                <hr className='hr1' />
                                                <div className="invoice">
                                                    <div className="d-flex justify-content-beetween">
                                                        <p>{text[52]}</p>
                                                        <p>{data.cost} €</p>
                                                    </div>
                                                    <div className="d-flex justify-content-beetween">
                                                        <p></p>

                                                    </div>
                                                    <hr className='hr2' />
                                                    <div className="d-flex justify-content-beetween">
                                                        <p>{text[54]}</p>
                                                        <p>{data.cost} €</p>

                                                    </div>
                                                    <div className="d-flex justify-content-beetween">
                                                        <p>{text[55]}</p>
                                                        <p>{data.savings} €</p>

                                                    </div>
                                                    <hr className='hr3' />
                                                    <div className="d-flex justify-content-beetween">
                                                        <p>{text[56]}</p>
                                                        <p>{Math.ceil(data.cost / data.savings)} {text[66]}</p>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="content-in quoteform">
                                            <div className="center">
                                                <h5>{text[37]}</h5>
                                                <p>{text[38]}</p>
                                                <div className="form">
                                                    <div className="form-group row inputgroups">
                                                        <div className="col-lg-6">
                                                            <input required type="text" onChange={(e) => { handleForm(e) }} name="name" value={form.name} placeholder={text[39]} />
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <input required type="text" onChange={(e) => { handleForm(e) }} name="sname" value={form.sname} placeholder={text[40]} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row inputgroups">
                                                        <div className="col-lg-6">
                                                            <input required type="text" onChange={(e) => { handleForm(e) }} name="mail" value={form.mail} placeholder={text[41]} />
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <input required type="text" onChange={(e) => { handleForm(e) }} name="phone" value={form.phone} placeholder={text[42]} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row inputgroups">
                                                        <div className="col-lg-6">
                                                            <input required type="text" onChange={(e) => { handleForm(e) }} name="address" value={form.address} placeholder={text[43]} />
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <input required type="text" onChange={(e) => { handleForm(e) }} name="zip" value={form.zip} placeholder={text[44]} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row inputgroups" >
                                                        <div className="col-lg-6">
                                                            <input required type="text" onChange={(e) => { handleForm(e) }} name="city" value={form.city} placeholder={text[45]} />
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <input required type="text" onChange={(e) => { handleForm(e) }} name="code" value={form.code} placeholder={text[46]} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <div className="col-lg-12">
                                                            <div className="checkbox-c">

                                                                <input type="checkbox" name="accept" id="accept" />
                                                                <label htmlFor="accept">{text[47]}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="errorbox" id='errorbox' style={{ "color": "white", "background": "red", "marginBottom": "10px", "borderRadius": "12px", "padding": "4px", "display": "none" }}>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="next">
                                                <button onClick={() => { decreasePage() }} id='buttonback'>{text[20]}</button>
                                                <button onClick={() => {
                                                    if (form.name && form.sname && form.mail && form.phone && form.address && form.zip && form.code && form.city) {

                                                        if (document.getElementById("accept").checked) {
                                                            document.getElementById('errorbox').innerHTML = ""
                                                            document.getElementById('errorbox').style.display = "none"

                                                            order()
                                                        } else {
                                                            document.getElementById('errorbox').style.display = "block"
                                                            document.getElementById('errorbox').innerHTML = text[64]
                                                        }

                                                    } else {

                                                        document.getElementById('errorbox').style.display = "block"
                                                        document.getElementById('errorbox').innerHTML = text[65]
                                                    }
                                                }} id='buttonnext'>{text[36]}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }[page]
                    }
                </div>
            </div>}
        </div>
    )
}

export default Solar;


