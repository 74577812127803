import React from 'react'
import Sbg from '../components/type/startbg';
import "../styles/about.css";

export const About = ({ text, img }) => {
  return (
    <div>
      <Sbg type={1} heading={text[0]} desc={text[1]} detail={text[2]} color="black" bg={img[1].url} />
      <div className="containe">

        <div className="parts">
          <div className="part text-center">
            <h4>{text[3]}</h4>
            <h3>{text[4]}</h3>
          </div>
          <div className="part">
            <div className="row">
              <div className="col-lg-6">
                <h5>{text[5]}</h5>
                <h4>{text[6]}</h4>
                <p>{text[7]}</p>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <img src={img[2].url} alt={img[2].alt} />
                </div>
              </div>
            </div>
          </div>
          <div className="part" id='corevalues'>
            <h5>{text[8]}</h5>
            <h4>{text[9]}</h4>
            <div className="row">
              <div className="col-lg-4">
                <h5>{text[10]}</h5>
                <p>{text[11]}</p>
              </div>
              <div className="col-lg-4">
                <h5>{text[12]}</h5>
                <p>{text[13]}</p>
              </div>
              <div className="col-lg-4">
                <h5>{text[14]}</h5>
                <p>{text[15]}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <h5>{text[16]}</h5>
                <p>{text[17]}</p>
              </div>
              <div className="col-lg-4">
                <h5>{text[18]}</h5>
                <p>{text[19]}</p>
              </div>
              <div className="col-lg-4">
                <h5>{text[20]}</h5>
                <p>{text[21]}</p>
              </div>
            </div>
          </div>
          <div className="part" id='aboutgallery'>
            <div className="row">
              <div className="col-lg-6">
                <img src={img[3].url} alt={img[3].alt} />
              </div>
              <div className="col-lg-6">
                <img src={img[4].url} alt={img[4].alt} />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default About;
