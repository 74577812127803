import React from 'react'
import '../styles/header.css'
import { Link } from "react-router-dom";

export const Header = ({ img, text, setlang, clang }) => {
  function changeLang(e) {
    setlang(e)

  }
  return (
    <header className="site-navbar js-sticky-header site-navbar-target" role="banner">
      <div className="container">
        <div className="row align-items-center position-relative">
          <div className=' d-flex' style={{"width": "100%"}}>

            <div className="site-logo">
              <Link to="/"><img src={img[21].url} alt="logo" width="200px" /></Link>

            </div>
            <button style={{"width": "fit-content"}} className="ml-auto text-center navbar-toggler d-lg-none" type="button" data-toggle="collapse" data-target="#navigationbar" aria-controls="navigationbar" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"><i className="fa-solid fa-bars"></i></span>
            </button>
          </div>

          <div className="col-12">
            <nav className="site-navigation text-right ml-auto " role="navigation">

              <div className="collapse navbar-collapse d-lg-none align-items-center text-center ml-auto mr-auto" id="navigationbar">
                <li data-toggle="collapse" data-target="#navigationbar"><Link to="/" >{text[0]}</Link></li>
                <li data-toggle="collapse" data-target="#navigationbar"><Link to="/calculator" >{text[1]}</Link></li>
                <li data-toggle="collapse" data-target="#navigationbar" ><Link to="/battery" >{text[2]}</Link></li>
                <li data-toggle="collapse" data-target="#navigationbar" ><Link to="/chargers" >{text[3]}</Link></li>
                <li data-toggle="collapse" data-target="#navigationbar" ><a href="https://cey-tech.be/" target="_blank">{text[4]}</a></li>
                <li data-toggle="collapse" data-target="#navigationbar" ><Link to="/gallery" >{text[5]}</Link></li>
                <li data-toggle="collapse" data-target="#navigationbar" ><Link to="/contact" >{text[6]}</Link> </li>
                <li data-toggle="collapse" data-target="#navigationbar" id='langen' onClick={() => { changeLang("du") }}><div className='langicon uklang'></div>NL</li>
                <li data-toggle="collapse" data-target="#navigationbar" id='langde' onClick={() => { changeLang("fr") }}><div className='langicon delang'></div>FR</li>

              </div>
              <div>
                <ul className="site-menu main-menu js-clone-nav ml-auto d-none d-lg-block">
                  <li><Link to="/" >{text[0]}</Link></li>
                  <li><Link to="/calculator" >{text[1]}</Link></li>
                  <li><Link to="/battery" >{text[2]}</Link></li>
                  <li><Link to="/chargers" >{text[3]}</Link></li>
                  <li><a href="https://cey-tech.be/" target="_blank">{text[4]}</a></li>
                  <li><Link to="/gallery" >{text[5]}</Link></li>
                  <li><Link to="/contact" >{text[6]}</Link> </li>
                  <li className="has-children" id="makemehover">
                    <a href="#" className="nav-link ">{clang === "fr" ? <div className='langicon delang'></div> : <div className='langicon uklang'></div>}</a>
                    <ul className="dropdown arrow-top">
                      <li id='langen' onClick={() => { changeLang("du") }}><div className='langicon uklang'></div>NL</li>
                      <li id='langde' onClick={() => { changeLang("fr") }}><div className='langicon delang'></div>FR</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </nav>
          </div>

        </div>
      </div>
    </header>
  )
}

export default Header;
