/*global google*/
import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  DrawingManager
} from "@react-google-maps/api";
import Geocode from "react-geocode";



Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
Geocode.enableDebug();
function Map(props) {

  var [center, setCenter] = useState()
  const [pol, setpol] = useState(null);
  const { zoom, address, callback } = props;

  const onPolygonComplete = (polygon) => {

    pol && pol.setMap(null);
    polygon.setEditable(true);
    setpol(polygon)

  }
  useEffect(() => {
    Geocode.fromAddress(address).then(
      response => {
        const coords = response.results[0].geometry.location;
        setCenter(coords)
      },
      error => {
        console.error(error);
      }
    );
  }, [address]);

  useEffect(() => {
    if (pol == null) {

    } else {
      var patho = pol.getPath()
      var cbt = Math.floor(google.maps.geometry.spherical.computeArea(patho))
      callback(cbt);
    }
  }, [pol, callback])
  if (!center) {
    <p>loading</p>
  } else {
    return (
      <GoogleMap
        mapTypeId='satellite'
        zoom={zoom}
        center={center}
        mapContainerStyle={{ width: '100%', height: '100%' }}
        options={{streetViewControl: false, disableDefaultUI: true, mapTypeId: "satellite", tilt: 0}}
        
      >
        <DrawingManager
          drawingMode={"polygon"}
          onPolygonComplete={onPolygonComplete}

          options={{
            drawingControl: true,
            drawingControlOptions: {
              position: "TOP_CENTER",
              drawingModes: ["polygon"]
            },
            polygonOptions: { editable: true },

          }}
        />
      </GoogleMap>
    );
  }
}

export default Map;
