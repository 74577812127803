import React, { useEffect, useState } from 'react'
import "../styles/contact.css";

export const Contact = ({ text, img }) => {
    var today = new Date();


    const [form, setForm] = useState({
        name: "",
        sname: "",
        email: "",
        phone: "",
        address: "",
        postalcode: "",
        type: "House",
        city: "",
        battery: false,
        charger: false,
        solar: false,
        extra: "",
        date: today
    });

    function onChange(e) {

        setForm({
            ...form,
            [e.target.name]: e.target.value

        })

    }
    function onChg(e) {
        setForm({
            ...form,
            type: e.target.options[e.target.selectedIndex].value
        })

    }
    function post() {
        fetch("https://api.cey-solar.com/api/contact", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(form)
        }).then(res => {
            if (res.status === 200) {
                document.getElementsByClassName('col-lg-8')[0].innerHTML = "Form has been successfully submitted";
            }
        });
    }
    return (
        <div className='contact'>
            <div className="contactbox">
                <div className="row">
                    <div className="col-lg-4">
                        <h2>
                            {text[0]}
                        </h2>
                        <p>
                            {text[1]}
                        </p>
                        <div className="contactinfo">
                            <div className="d-flex justify-content-start">
                                <i className="fa-solid fa-phone fa-2x"></i><a href="tel:3226481069"><p>+ 32 2 648 10 69</p></a>
                            </div>
                            <div className="d-flex justify-content-start">
                                <i className="fa-brands fa-whatsapp fa-2x"></i><a href="https://wa.me/32489921363"><p>+ 32 489 92 13 63</p></a>
                            </div>
                            <div className="d-flex justify-content-start">
                                <i className="fas fa-envelope fa-2x"></i><a href="mailto:info@cey-tech.be"><p>info@cey-tech.be</p></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="row">
                            <div className="col-lg-3">
                                <h3>{text[2]}</h3>
                            </div>
                            <div className="col-lg-9">
                                <div className="form">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <select value={form.type} onChange={(e) => onChg(e)} name="ctype" id="ctype" >
                                                <option value="House">{text[3]}</option>
                                                <option value="Commercial">{text[4]}</option>
                                                <option value="Housing Cooperative">{text[5]}</option>
                                                <option value="Economic Association">{text[6]}</option>
                                                <option value="Farm">{text[7]}</option>
                                                <option value="Utility">{text[8]}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <input type="text" onChange={(e) => onChange(e)} value={form.name} name="name" id="name" placeholder={text[9]} />
                                        </div>
                                        <div className="col-lg-6">
                                            <input type="text" onChange={(e) => onChange(e)} value={form.sname} name="sname" id="sname" placeholder={text[10]} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <input type="tel" onChange={(e) => onChange(e)} value={form.phone} name="phone" id="phone" placeholder={text[11]} />
                                        </div>
                                        <div className="col-lg-6">
                                            <input type="email" onChange={(e) => onChange(e)} value={form.email} name="email" id="email" placeholder={text[12]} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <input type="text" onChange={(e) => onChange(e)} value={form.address} name="address" id="address" placeholder={text[13]} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <input type="text" onChange={(e) => onChange(e)} value={form.postalcode} name="postalcode" id="postalcode" placeholder={text[14]} />
                                        </div>
                                        <div className="col-lg-6">
                                            <input type="text" onChange={(e) => onChange(e)} value={form.city} name="city" id="city" placeholder={text[15]} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <input type="text" onChange={(e) => onChange(e)} value={form.extra} name="extra" id="extra" placeholder={text[16]} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <h6>{text[17]}</h6>

                                        <div className="col-lg-12 d-flex additions">
                                            <div className={form.battery ? "batteryad selectedaddition" : "batteryad"} onClick={() => { setForm({ ...form, battery: !form.battery }) }}>
                                                <i className="fa-solid fa-battery-full"></i> {text[18]}</div>
                                            <div className={form.charger ? "chargerad selectedaddition" : "chargerad"} onClick={() => { setForm({ ...form, charger: !form.charger }) }}>
                                                <i className="fa-solid fa-bolt"></i> {text[19]}</div>
                                            <div className={form.solar ? "chargerad selectedaddition" : "chargerad"} onClick={() => { setForm({ ...form, solar: !form.solar }) }}>
                                                <i className="fa-solid fa-bolt"></i> {text[20]}</div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 d-flex justify-content-start">
                                            <input id="acceptbx" type="checkbox" name="accept" />
                                            <label htmlFor="accept">{text[21]}</label>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <p className="errorboxgt"></p>
                                    </div>
                                    <div className="row justify-content-end">
                                        <button onClick={() => {
                                            if (document.getElementById("acceptbx").checked === true) {
                                                post()
                                            } else {
                                                document.getElementsByClassName('errorboxgt').innerHTML = "Please Accept the policy";
                                            }
                                        }}>{text[22]}</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;
