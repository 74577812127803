import React, { useState, useEffect } from 'react'
import "../styles/panel.css";
import produce from "immer";

export const Panel = ({ text, img }) => {
    const [value, setvalue] = useState();
    const [customer, setcustomer] = useState();
    const [status, setStatus] = useState();
    const [locale, setlocale] = useState(text);
    const [image, setimage] = useState(img);
    const [gal, setgal] = useState();
    const [logged, setLogged] = useState(false);
    const [pass, setPass] = useState();
    const [error, setError] = useState();
    const [contact, setContact] = useState();
    useEffect(() => {
        setStatus('Loading');
        fetch('https://api.cey-solar.com/api/panel')
            .then(response => response.json())
            .then(setvalue)
            .then(() => fetch('https://api.cey-solar.com/api/customer')
                .then(response => response.json())
                .then(setcustomer)
                .then(() => fetch('https://api.cey-solar.com/api/gallery')
                    .then(response => response.json())
                    .then(setgal)
                    .then(() => fetch('https://api.cey-solar.com/api/contact')
                        .then(response => response.json())
                        .then(setContact)
                        .then(() => setStatus('Success'))
                        .catch(() => setStatus('Error')))
                    .catch(() => setStatus('Error'))
                )
                .catch(() => setStatus('Error'))
            )
            .catch(() => setStatus('Error'));

    }, []);
    function handleChange(e) {
        setvalue({
            ...value,
            [e.target.name]: e.target.value
        })

    }
    function handleLang(e, l, p) {
        let array = locale[p][l]
        array[Number(e.target.name)] = e.target.value
        setlocale({
            ...locale,
            [p]: {
                ...locale[p],
                [l]: array

            }

        })
        console.log(locale)
    }
    function handleImg(e, x) {
        const { name, value } = e.target;
        setimage(prev => prev.map((whatever, idx) => idx === x ? { ...whatever, [name]: value } : whatever));
    }
    function handleGal(e, x, idx) {
        const { name, value } = e.target
        setgal(
            produce((draft) => {
                const item = draft.find((item) => item.type === gal[x].type);
                item.items[idx][name] = value;
            })
        );
    }
    function handleGala(i) {
        var init = gal[i].items
        var newel = [...init, { place: "", kwpower: "", oilsaved: "", savedperyear: "", panel: "", img: "" }]
        
        setgal(prev => prev.map((whatever, idx) => idx === i ? { ...whatever, items: newel } : whatever))
    }


    function delCustomer(i, e) {
        if (e === 0) {
            let tempdata = [...customer]
            tempdata.splice(i, 1)
            setcustomer(tempdata)
            console.log(customer)
        } else {
            let tempdata = [...contact]
            tempdata.splice(i, 1)
            setContact(tempdata)
            console.log(contact)
        }
    }
    function post() {
        fetch("https://api.cey-solar.com/api/panel?pass=" + pass, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(value)
        }).then(res => {
        });
    }
    function localepost() {
        fetch("https://api.cey-solar.com/api/locale?pass=" + pass, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(locale)
        }).then(res => {
        });
    }
    function imgPost() {
        fetch("https://api.cey-solar.com/api/img?pass=" + pass, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(image)
        }).then(res => {
        });
    }
    function galPost() {
        fetch("https://api.cey-solar.com/api/gallery?pass=" + pass, {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(gal)
        }).then(res => {
        });
    }
    function delPost(e) {
        if (e === 0) {
            fetch("https://api.cey-solar.com/api/customer/del?pass=" + pass, {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(customer)
            }).then(res => {
            });
        } else {
            fetch("https://api.cey-solar.com/api/contact/del?pass=" + pass, {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(contact)
            }).then(res => {
            });
        }
    }
    function log() {
        fetch("https://api.cey-solar.com/api/login", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    "pass": document.getElementById("passinput").value
                }
            )
        }).then(res => {
            if (res.status === 200) {
                setLogged(true)
                setPass(document.getElementById("passinput").value)
                setError("")
            } else {
                setLogged(false)
                setError("Wrong password")
            }
        });
    }
    return (
        <div className='panel'>
            {
                logged ? <>{status === 'Loading' && <div>Loading...</div>}
                    {status === 'Error' && <div>There was an error</div>}
                    {status === 'Success' &&
                        <>
                            <h1>Panel</h1>
                            <hr />

                            <div className="item">
                                <h1>Values</h1>

                                <div className="row">
                                    <div className="col-lg-6">

                                        <div className='inputs'>

                                            {Object.keys(value).map(function (k) {

                                                return (
                                                    <div key={"ke" + String(k)} className="input d-flex justify-content-beetween">
                                                        <p>{k}</p>
                                                        <input onChange={(e) => { handleChange(e) }} type="text" name={k} id={k} value={value[k]} />
                                                    </div>
                                                );
                                            })}
                                        </div>



                                        <button onClick={() => { post() }}>Save</button>
                                    </div>
                                    <div className="col-lg-6">
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <h1>Language</h1>
                                <div className="locales">
                                    {
                                        Object.keys(locale).map((v) => {
                                            return (
                                                <div key={"as3l" + String(v)}>
                                                    <h4 key={"ess" + String(v)}>Page : {String(v)}</h4>
                                                    <div key={"es" + String(v)} className="inputlist">
                                                        {
                                                            locale[v].en.map((x, i) => {
                                                                return (
                                                                    <div key={"e" + String(i)} className="row">
                                                                        <div className="col-lg-4">
                                                                            <p>{x}</p>
                                                                        </div>
                                                                        <div className="col-lg-4">
                                                                            <textarea value={locale[v].fr[i]} onChange={(e) => { handleLang(e, "fr", v) }} name={String(i)} cols="30" rows="2"></textarea>
                                                                        </div>
                                                                        <div className="col-lg-4">
                                                                            <textarea value={locale[v].du[i]} onChange={(e) => { handleLang(e, "du", v) }} name={String(i)} cols="30" rows="2"></textarea>

                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <button style={{ "width": "100%", "padding": "8px", "margin": "16px 0px" }} onClick={() => { localepost() }}>Save</button>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div className="item">
                                <h1>Images</h1>
                                <div className="imagelist">
                                    <div className="row">
                                        {
                                            image.map((img, i) => {
                                                return (
                                                    <div className="col-lg-3 imgcrd" key={"ls" + String(i)}>
                                                        <img src={image[i].url} alt={image[i].alt} />
                                                        <div className="alignbottom">
                                                            <p>{image[i].alt}</p>
                                                            <textarea onChange={(e) => { handleImg(e, i) }} name='url' value={image[i].url} rows="2"></textarea>
                                                            <input onChange={(e) => { handleImg(e, i) }} name='alt' type="text" value={image[i].alt} />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <button style={{ "width": "100%", "marginTop": "16px" }} onClick={() => { imgPost() }}>Save</button>
                                </div>
                            </div>
                            <div className="item">
                                <h1>Gallery</h1>
                                <div className="gallerylist">
                                    {
                                        gal.map((el, i) => {
                                            return (
                                                <div key={"des" + String(i)} className="galitem">
                                                    <h3>{gal[i].type}</h3>
                                                    <div className="galcards row">
                                                        {
                                                            gal[i].items.map((el, idx) => {
                                                                return (
                                                                    <div key={"desp" + String(idx)} className="galcard col-lg-4">
                                                                        <img src={gal[i].items[idx].img} alt="house" />
                                                                        <div><label htmlFor="place">place</label>
                                                                            <input onChange={(e) => { handleGal(e, i, idx) }} name='place' type="text" value={gal[i].items[idx].place} /></div>
                                                                        <div><label htmlFor="kwpower">kwpower</label>
                                                                            <input onChange={(e) => { handleGal(e, i, idx) }} name='kwpower' type="text" value={gal[i].items[idx].kwpower} /></div>
                                                                        <div><label htmlFor="oilsaved">oilsaved</label>
                                                                            <input onChange={(e) => { handleGal(e, i, idx) }} name='oilsaved' type="text" value={gal[i].items[idx].oilsaved} /></div>
                                                                        <div><label htmlFor="savedperyear">savedperyear</label>
                                                                            <input onChange={(e) => { handleGal(e, i, idx) }} name='savedperyear' type="text" value={gal[i].items[idx].savedperyear} /></div>
                                                                        <div><label htmlFor="panel">panel</label>
                                                                            <input onChange={(e) => { handleGal(e, i, idx) }} name='panel' type="text" value={gal[i].items[idx].panel} /></div>
                                                                        <div><label htmlFor="img">img</label>
                                                                            <input onChange={(e) => { handleGal(e, i, idx) }} name='img' type="text" value={gal[i].items[idx].img} /></div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        <div className="galcard newgal col-lg-4">
                                                            <h6>New Item</h6>
                                                            <button onClick={() => handleGala(i)}>Add new item</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    <button style={{ "width": "100%", "marginTop": "16px" }} onClick={() => { galPost() }}>Save</button>
                                </div>
                            </div>
                            <div className="item">
                                <h1>Customers</h1>
                                <button onClick={() => delPost(0)}>Save Customer Form</button>

                                <div className="customerlist ">
                                    <div className='row'>

                                        {customer.map((k, i) => {

                                            return (
                                                <div key={"sew" + String(i)} className="col-lg-4 customercard">
                                                    <button onClick={() => delCustomer(i, 0)} >Delete Customer</button>

                                                    <p style={{ "borderBottom": "1px solid black" }}>{Number(i) + 1}</p>
                                                    {
                                                        Object.keys(k).map(function (h) {
                                                            if (h === "data") {
                                                                return (
                                                                    <div key={"n1sn" + String(h)}>
                                                                        {Object.keys(k[h]).map(function (t) {
                                                                            return (
                                                                                <p key={"sea" + String(t)}>{t} : {String(k[h][t])}</p>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                )
                                                            } else {
                                                                return (
                                                                    <p key={"seq" + String(h)}>{h} : {String(k[h])}</p>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <h1>Contact Form</h1>
                                <button onClick={() => delPost(1)}>Save Contact Form</button>
                                <div className="customerlist ">
                                    <div className='row'>

                                        {contact.map((k, i) => {

                                            return (
                                                <div key={"sew" + String(i)} className="col-lg-4 customercard">
                                                    <button onClick={() => delCustomer(i, 1)} >Delete Form</button>
                                                    <p style={{ "borderBottom": "1px solid black" }}>{Number(i) + 1}</p>
                                                    {
                                                        Object.keys(k).map(function (h) {
                                                            if (h === "data") {
                                                                return (
                                                                    <div key={"n1sn" + String(h)}>
                                                                        {Object.keys(k[h]).map(function (t) {
                                                                            return (
                                                                                <p key={"sea" + String(t)}>{t} : {String(k[h][t])}</p>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                )
                                                            } else {
                                                                return (
                                                                    <p key={"seq" + String(h)}>{h} : {String(k[h])}</p>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </>
                    }</> : <div className="login">
                    <h3>Login to panel</h3>
                    <input id='passinput' type="text" placeholder='password' />
                    <div className="errorbox">
                        {error}
                    </div>
                    <button onClick={() => { log() }}>Log in</button>
                </div>
            }
        </div>
    )
}
export default Panel;
