import React from 'react'
import { useState } from 'react';

export const Accordion = ({ q, a }) => {
    const [isActive, setIsActive] = useState(false);

    return (
        <div className="quest">
            <div className="row" onClick={() => setIsActive(!isActive)}>
                <h6 className='col align-self-start'>{q}</h6>
                <i className='col align-self-end' class={isActive? "fa-solid fa-minus": "fa-solid fa-plus"}></i>
            </div>
            {isActive && <div className="answer"><p>{a}</p></div>}
            
        </div>
    )
}

export default Accordion;